import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// `
// const BrandsLogo = styled.div`
//   cursor: pointer;
//   display: flex;
//   position: relative;

//   font-weight: bold;
//   // padding: 4px 1rem;
//   justify-content: center;

//   img {
//     margin: 0;
//   }
//   .gatsby-image-wrapper {
//     width: 260px;
//     height: 130px;

//   }
//   picture {
//     img {
//       margin: 0;
//       // filter: grayscale(1);
//       opacity: 0.7 !important;
//       &:hover {
//         filter: none;
//         opacity: 1 !important;
//       }
//     }
//   }
// `

const ProductStrip = ({ products, big, dark, title, subtitle }) => {
  // console.log("STRIP", products);
  const settings = {
    dots: false,
    arrows: false,
    pauseOnHover: false,
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          dots: false,
          slidesToShow: 1,
          speed: 6000,
        },
      },
    ],
  };
  return (
    <div className="bg-[#ffffff]   relative z-[1] overflow-hidden">
      <div className=" mx-auto  max-w-7xl xs:px-3 pt-5">
        <div className=" mb-8 py-3 pt-4 px-3 ">
          
          <Slider {...settings} slickPlay>
            {products.map((product, i) => {
              return (
                <div className="brand-card" key={i}>
                  <div className="brand-logo">
                    <GatsbyImage
                      imgStyle={{ height: "auto" }}
                      alt={`${product.title} Logo`}
                      title={`${product.title} Logo`}
                      image={product?.image?.asset?.gatsbyImageData}
                      objectFit="contain"
                    />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ProductStrip;
