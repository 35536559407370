import * as React from "react";
import {graphql} from 'gatsby'
import CompanyProfile from "../components/CompanyProfile";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import SEO from '../components/Seo'
import Config from '../config/site'
import ProductStrip from "../components/ProductStrip";
import PartnerSection from "../components/PartnerSection";
const IndexPage = (props) => {
  const { data } = props;
  // console.log(data);
  return (
    <Layout details={data}>
      <SEO
        pathname="/"
        title={`Nastesco Marine Pte Ltd | Marine Parts`}
        keywords={["Nastesco Home","Nastesco Landing Page"]}
        />
      <Hero />
      <CompanyProfile details={data?.company_profile?.nodes?.[0]} />
      <ProductStrip products={data?.galleryStrip?.nodes} />
      {/* <PartnerSection /> */}
    </Layout>
  );
};

export const query = graphql`
  query {
    settings: allSanitySettings {
      nodes {
        id
        address_details {
          address_text
          city
          country
          office_location {
            latitude
            longitude
          }
          site_email
          site_phone
          state
        }
        business_hours {
          closed
          open
        }
        copyright_year
        shipserve_details {
          logo {
            asset {
              url
            }
          }
          trade_id
        }
        site_logo {
          asset {
            url
          }
        }
        site_name
        social_links {
          facebook_link
          linkedin_link
          tweeter_link
        }
      }
    }
    company_profile: allSanityCompanyProfile {
      nodes {
        about_us
        full_description {
          description
          description_image {
            asset {
              url
            }
          }
        }
        policy_details {
          description
          policy_image {
            asset {
              url
            }
          }
        }
      }
    }
    gallery: allSanityGallery {
      nodes {
        _id
        description
        title
        image {
          asset {
            url
          }
        }
      }
      totalCount
    }
    galleryStrip: allSanityGallery {
      nodes {
        _id
        description
        title
        image {
          asset {
            gatsbyImageData
          }
        }
      }
      totalCount
    }
    product_categories: allSanityProductCategory {
      nodes {
        title
        description
        _id
      }
      totalCount
    }
    products: allSanityProducts {
      totalCount
      nodes {
        _id
        detailed_description
        image {
          asset {
            url
          }
        }
        product_category {
          title
          _id
        }
        sort_description
        title
        sub_part_details {
          description
          title
          image {
            asset {
              url
            }
          }
        }
      }
    }
    services: allSanityServices {
      totalCount
      nodes {
        _id
        value_added_services
        key_services
      }
    }
    slider: allSanitySlider {
      totalCount
      nodes {
        _id
        slider_item {
          description
          title
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
export default IndexPage;
